<template>
	<div class="resources">
		<Nav />
		<span class="overlay" :class="{active: isMenuOpen}"></span>
		<div class="header header-standard">
			<HeaderNavBar back />
		</div><!-- header -->
		<div class="header-image red"></div><!-- header image -->
		<div class="content-wrap content-wrap-alt content-wrap-nav">
			<SectionTitle
				icon="StarIcon"
				title="My Resources"
				colour="red"
			/>
			<div class="section-gap section-ports">
				<div class="section-title-wrap">
					<h2 class="section-title">{{ count }} Resource{{ count > 1 ? 's' : '' }}</h2>
				</div>
				<div class="card-row-wrap">
					<CardRow
                        v-for="resource in resources"
                        :key="resource.id"
						routeName="ResourcesSingle"
                        :id="resource.id"
						class="card-row-block-resources"
						:title="resource.title"
						:tag="resource.category"
						:type="resource.simplified_type"
					/>
				</div><!-- port card wrap -->
			</div>
		</div>
		<ResourceSearchOverlay />
		<ResourceAdviceOverlay />
		<Menu />
	</div>
</template>
<script>
	import HeaderNavBar from '@/components/HeaderNavBar.vue';
	import Nav from '@/components/Nav.vue';
	import Menu from '@/components/Menu.vue';
	import CardRow from '@/components/CardRow.vue';
	import SectionTitle from '@/components/SectionTitle.vue';
	import ResourceSearchOverlay from '@/components/ResourceSearchOverlay.vue';
	import ResourceAdviceOverlay from '@/components/ResourceAdviceOverlay.vue';
    import UserService from '@/services/UserService';
	export default {
		name: 'ResourcesMy',
		components: {
			Nav,
			HeaderNavBar,
			Menu,
			CardRow,
			SectionTitle,
			ResourceSearchOverlay,
			ResourceAdviceOverlay
		},
		data () {
	        return {
                count: 0,
                resources: []
	        };
	    },
	    methods: {
            getResources () {
                return UserService.getResources().then((response) => {
                    this.count = response.data.count;
                    this.resources = response.data.resources;
                });
            }
        },
	    computed: {
			isMenuOpen () {
	            return this.$store.state.isMenuOpen;
	        }
		},
		mounted () {
            this.getResources();
        }
	};
</script>
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.section-title {
		color: $grey;
	}
</style>
